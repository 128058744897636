import request from "@/api_lm/request"
const baseUrl = process.env.VUE_APP_WLM_BASE_URL

/**
 * 个性化配置
 */
export function getWlmByType(query,isError = true) {
  return request({
    url: `${baseUrl}/wlm-api/apigb/v1/sys/banner/get-by-type`,
    method: "get",
    params: query,
    headers:{
      isError
    },
  });
}

export function getCartCount(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/shopping-car/count`,
    method: "get",
    params: query,
  });
}

/**
 * 帮助中心
 */
export function getHelpInfo(id) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/v1/lm/help/info/${id}`,
    method: "get",
  });
}

/**
 * 获取验证码接口
 */
export function getCaptcha(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/lianmeng/auth/captcha`,
    method: "get",
    params: query,
  });
}

/**
 * 获取短信验证码接口
 */
export function sendSmsApi(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/lianmeng/auth/smsl`,
    method: "post",
    params: query,
  });
}

/**
 * 获取短信验证码接口（注册用）
 */
export function sendSmsRApi(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/lianmeng/auth/smsr`,
    method: "post",
    params: query,
  });
}


/**
 * 密码登录接口
 */

export function pwdLoginApi(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/lianmeng/auth/token`,
    method: "post",
    data: query,
  });
}

/**
 * 短信登录接口
 */

export function smsLoginApi(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/lianmeng/auth/sms-login`,
    method: "post",
    data: query,
  });
}


/**
 * 用户注册
 */

export function smsRegisterApi(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/lianmeng/auth/register`,
    method: "post",
    data: query,
  });
}

export function smsRegister2Api(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/lianmeng/auth/jregister`,
    method: "post",
    data: query,
  });
}
/**
 * 修改密码
 */

export function setPassword(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/lianmeng/auth/passwd`,
    method: "put",
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}


/**
 * 获取字典数据  code
 * @params params
 */
export function commonGetDicConfigList(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/dic`,
    method: "get",
    params: query,
  });
}


/**
 * 获取用户基本信息
 * @params params
 */
export function getUserInfo(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/user-info`,
    method: "get",
    params: query,
  });
}


/**
 * 外联盟用户登出接口
 * @params params
 */
export function logout(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/lianmeng/auth/logout`,
    method: "post",
    data: query,
  });
}

/**
 * 外联盟用户登出接口
 * @params params
 */
export function inviteLm(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmcp/lianmeng/auth/invite-lm`,
    method: "get",
    params: query,
  });
}

/**
 * 查看3D接口
 * @params params
 */
export function findGoods3d(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/find-goods/3d`,
    method: "get",
    params: query,
  });
}

/**
 * 账号管理功能
 * @params params
 */
export function authSwitch(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/lianmeng/auth/switch`,
    method: "post",
    params: query,
  });
}

/**
 * 查询用户是否有切换用户的权限
 * @params params
 */
export function ifSwitch(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/lianmeng/auth/if-switch`,
    method: "get",
    params: query,
  });
}

/**
 * 获取3d图纸模型配置信息
 * @params params
 */
export function drawingModelInfo(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/user/drawing-model-info`,
    method: "get",
    params: query,
  });
}


/**
 * 修改图纸显示信息
 * @params params
 */
export function modifyDrawingModel(query) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/v1/lm/user/modify-drawing-model`,
    method: "post",
    data: query,
  });
}

/**
 * 登录状态下，修改密码
 * @params params
 */
export function smsPasswd(data) {
  return request({
    url: `${baseUrl}/wlm-api/lmap/lianmeng/auth/sms-passwd`,
    method: "post",
    data,
  });
}
