import request from "@/api_lm/request"
const baseUrl = process.env.VUE_APP_BASE_URL
const preUrl = process.env.VUE_APP_BASE_URL

/**
 * 个性化配置
 */
export function getByType(query,isError = true) {
  return request({
    url: `${baseUrl}/asm-api/cp/sys/v1/sys/banner/get-by-type`,
    method: "get",
    params: query,
    headers:{
      isError
    },
  });
}

/**
 * 个性化模块
 */
export function getByBlockNum(query,isError = true) {
  return request({
    url: `${baseUrl}/apiz/rest/asm/center/v1/lm/get-by-block-num`,
    method: "get",
    params: query,
    headers:{
      isError
    },
  });
}

/**
 * 权限控制
 */
export function getUserRights(query,isError = true) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Common/GetUserRights`,
    method: "post",
    data: query,
    headers:{
      isError,
    },
  });
}

/**
 * 获取富文本信息
 */
export function getHelpInfo(query) {
  return request({
    url: baseUrl + `/apiz/rest/asm/center/v1/help/info/${query}`,
    method: "get",
    params: query,
  });
}

/**
 * 获取商户消息接口（商户）
 */
export function GetTenantAllianceNotification(query= {},isError = true) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/PlatformManage/GetTenantAllianceNotification`,
    method: "post",
    data: query,
    headers:{
      isError
    },
  });
}


/**
 * 获取商户联盟消息接口（平台）
 */
export function GetPlatformAllianceNotification(query= {}) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/PlatformManage/GetPlatformAllianceNotification`,
    method: "post",
    data: query,
  });
}
//

/**
 * 申请联盟
 */
export function ApplyAlliance(query= {}) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/PlatformManage/ApplyAlliance`,
    method: "post",
    data: query,
  });
}

/**
 * 申请结盟接口
 */
export function ApplyTenantAlliance(query= {}) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/Tenant/ApplyTenantAlliance`,
    method: "post",
    data: query,
  });
}

/**
 * 邀请开通联盟接口
 */
export function InviteOpenAlliance(query= {},isError = true) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/PlatformManage/InviteOpenAlliance`,
    method: "post",
    data: query,
    headers:{
      isError
    },
  });
}


/**
 * 查询商户联盟状态
 */
export function GetPlatformAllianceStatusById(query= {}) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/PlatformManage/GetPlatformAllianceStatusById`,
    method: "get",
    params: query,
  });
}

/**
 * 获取商户邀请消息接口（商户）
 */
export function GetTenantAllianceInviteNotification(query= {},isError = true) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/PlatformManage/GetTenantAllianceInviteNotification`,
    method: "post",
    data: query,
    headers:{
      isError
    },
  });
}

/**
 * 获取联盟商品档未引入页面
 */
export function GetAllianceNotFactoryProductUrl(query) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/FactoryProduct/GetAllianceNotFactoryProductUrl`,
    method: "post",
    data: query,
  });
}


/**
 * 获取联系人列表页面地址
 */
export function GetAllianceContactUrl(query) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/PlatformManage/GetAllianceContactUrl`,
    method: "get",
    params: query,
  });
}

/**
 * 获取发起联盟申请页地址
 */
export function GetApplyAllianceUrl(query) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/Tenant/GetApplyAllianceUrl`,
    method: "get",
    params: query,
  });
}

/**
 * 消息已读接口
 */
export function UpdateNotificationStatusToReaded(query) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/PlatformManage/UpdateNotificationStatusToReaded`,
    method: "post",
    params: query,
  });
}

/**
 * 消息处理接口
 */
export function HandleNotification(query) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/PlatformManage/HandleNotification`,
    method: "post",
    params: query,
  });
}

/**
 * 开通申请状态
 */
export function GetAllianceOpeningConditionStatus(query) {
  return request({
    url: `${baseUrl}/gylapi/v1/Api/Alliance/PlatformManage/GetAllianceOpeningConditionStatus`,
    method: "get",
    params: query,
  });
}

/**
 * 跳转ERP
 */
export function jumpToERPApi(path) {
  return request({
    url: `${baseUrl}/gylapi`+path,
    method: "get",
  });
}

/**
 * 紧优网数据
 */
export function getLmBiTenant(query) {
  return request({
    url: baseUrl + `/apiz/rest/asm/center/v1/lm/bi/tenant`,
    method: "get",
    params: query,
  });
}

