import {getUserInfo,getCartCount} from "@/api_lm/lmPortals";
import {createStorageUtils} from "@/store/storage";
import {getCookie} from "@/utils/utils";

// 工厂函数
const STORAGE_KEY = 'lmPortals';
const storageUtils = createStorageUtils(STORAGE_KEY);

const getDefaultState = () => {
  return {
    userInfo: storageUtils.get().userInfo || '', // 用户信息
    token: storageUtils.get().token || '', // 从存储中获取 token
    orderNum: {}, // 购物车数量
    selectedSupplier:  storageUtils.get().selectedSupplier || [], // 选择的供应商
    shopTenantId:'', // 店铺id
  }
}

const mutations = {
  // 重置state
  RESET_STORE: (state) => {
    storageUtils.clear();
    Object.assign(state, getDefaultState())
  },

  SET_TOKEN(state, token) {
    state.token = token;
    storageUtils.updateItem('token', token);
  },

  CLEAR_TOKEN(state) {
    state.token = '';
    storageUtils.removeItem('token');
  },

  SET_USERINFO(state, value) {
    state.userInfo = value;
    storageUtils.updateItem('userInfo', value);
  },

  CLEAR_USER_INFO(state) {
    state.userInfo = '';
    storageUtils.removeItem('userInfo');
  },

  SET_SHOP_TENANT_ID(state, value) {
    state.shopTenantId = value;
    // storageUtils.updateItem('shopTenantId', value);
  },


  CLEAR_SHOP_TENANT_ID(state) {
    state.shopTenantId = '';
    // storageUtils.removeItem('shopTenantId');
  },

  SET_ORDER_NUM(state, value) {
    state.orderNum = value;
  },
  SET_SELECT_SUPPLIER(state, value){
    state.selectedSupplier = value
    storageUtils.updateItem('selectedSupplier', value);
    localStorage.setItem('selectedSupplier', JSON.stringify(value))
  }
};

const actions = {
  // 重置模块
  async resetStore({ commit }) {
    commit('RESET_STORE');
  },

  setToken({commit}, token='') {
    // commit('SET_TOKEN', token);
    commit('SET_TOKEN', getCookie('_wlm_t__0'));
  },

  clearToken({commit}) {
    commit('CLEAR_TOKEN');
  },

  // 更新用户状态
  async updateUserStatus({state,dispatch},config = { route: '' }){
    let {route} = config
    let newToken = getCookie('_wlm_t__0')
    console.log({newToken,oldToken:state.token})

    if (newToken !== state.token){
      dispatch('setToken'); // 更新token
      if (newToken){ // 如果有登录
        await dispatch('getUserInfo'); // userInfo
      }else {
        dispatch('resetStore') //没登录清除所有状态
      }
    }
    //如果登录了并且cookie一致则获取购物车数量
    if (newToken && newToken === state.token){
      await dispatch('getCartCount',{route});
    }
  },

  // 获取购物车数量
  async getCartCount({ commit, state }, config = { route: '' }) {
      let {route} = config
      console.log(route,'route')
      if (!state.userInfo) return commit('SET_ORDER_NUM', {});
      const query = {}
      if(route && route.meta.module === 'lmShop' ) query.supplierId = route.query.tid
        try {
          const {data} = await getCartCount(query);
          // console.log(data, '获取购物车数量');
          commit('SET_ORDER_NUM', data);
        }catch (e){
          commit('SET_ORDER_NUM', {});
        }
  },

  async getUserInfo({commit}) {
    const {data} = await getUserInfo();
    // console.log(data, '获取用户基本信息');
    commit('SET_USERINFO', data);
  },

  setShopTenantId({commit}, value) {
    commit('SET_SHOP_TENANT_ID', value);
  },

  clearShopTenantId({commit}) {
    commit('CLEAR_SHOP_TENANT_ID');
  },


  setSelectSupplier({commit}, value){
    commit('SET_SELECT_SUPPLIER',value)
  }
};

const getters = {
  token: state => state.token,
};

export default {
  namespaced: true,
  state:getDefaultState(),
  mutations,
  actions,
  getters
};
